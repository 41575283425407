import { 
    useEffect, 
    useState 
} from "react";

import "./AutoTable.css";

export default function AutoTable(props) {
    const [th, setTh] = useState([]);
    const [filterRow, setFilterRow] = useState([]);

    useEffect(() => {
        if (props.th === undefined) return;

        const resTh = [];
        const resFilterRow = [];

        props.th.forEach((row) => {
            const {
                id,
                name,
                width,
                span,
                type
            } = row;

            const rTh = (
                <th 
                    className="regular-th"
                    key={ id } 
                    scope="col"
                    colSpan={ span }
                    style={{ width: width }}
                    onClick={() => { if (props.onSort !== undefined) props.onSort(id) }}
                >
                { name }
                </th>
            );

            const rFilterRow = (
                <th
                    className="filter-th"
                    key={ `filter_${id}` }
                    colSpan={ span }
                    style={{ width: width }}
                >
                {
                    type !== undefined && (
                        <input 
                            className="filter-input"
                            type={ type } 
                            onChange={(e) => { 
                                if(props.onSearch !== undefined) { 
                                    props.onSearch(id, type === "checkbox" ? e.target.checked : e.target.value);
                                }
                            }} 
                        />
                    )
                }
                </th>
            );

            resTh.push(rTh);
            resFilterRow.push(rFilterRow);
        });

        setTh(resTh);
        setFilterRow(resFilterRow);
    }, []);

    return (
        <div className="autotable-container" { ...props }>
            <table className="autotable-header-table">
                <thead>
                    <tr className="tr-header">
                        {th}
                    </tr>
                    {
                        !props.noFilter && (
                            <tr className="tr-filter">
                                {filterRow}
                            </tr>
                        )
                    }
                </thead>
                <tbody>{ props.children }</tbody>
            </table>
        </div>
    );
}
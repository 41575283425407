import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthorized: false,
        isAdmin: false,
        username: ""
    },
    reducers: {
        setAuthorized: (state, action) => {
            const { isAdmin } = action.payload;

            return {
                ...state,
                isAuthorized: true,
                isAdmin: isAdmin
            };
        },
        setUnauthorized: (state) => {
            return {
                ...state,
                isAuthorized: false,
                isAdmin: false
            };
        },
        setUsername: (state, action) => {
            return {
                ...state,
                username: action.payload
            };
        }
    }
});

export const { 
    setAuthorized,
    setUnauthorized,
    setUsername 
} = authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state) => state.auth;
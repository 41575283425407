import dbList from "../DBList.json";

const {
    api_server,
    export_config
} = dbList.server;

const url = api_server + export_config;

export async function GetExportConf() {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function AddExportConf(AConf) {
    const httpRes = await fetch(url, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AConf)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function UpdateExportConf(AConf) {
    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AConf)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function DeleteExportConf(AId) {
    const httpRes = await fetch(`${ url }/${AId}`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}
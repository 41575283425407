import { 
    useState,
    useEffect 
} from "react";

import { 
    useDispatch, 
    useSelector
} from "react-redux";

import {
    showMsg,
    hideMsg,
    setLoadingNow,
    setLoadingComplete,
    pageSelector
} from "../store/pageSlice";

import {
    Button,
    SaveButton,
    CancelButton,
    TextInput,
    CheckBox,
    CheckBoxCollection
} from "../components/Input";

import { 
    GetEmployeeGroup,
    AddEmployeeGroup,
    UpdateEmployeeGroup,
    DeleteEmployeeGroup
} from "../api/employeeGroup";

import { GetClockDataTag } from "../api/clockDataTag";
import { GetLocations } from "../api/location";

import Help from "./Help";

import "../App.css";

export default function EmployeeGroup() {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);

    const [lst, setLst] = useState([]);

    const [ds, setDs] = useState([]);

    const [dsMasterTag, setDsMasterTag] = useState([]);
    const [dsTag, setDsTag] = useState([]);

    const [dsMasterLoc, setDsMasterLoc] = useState([]);
    const [dsLoc, setDsLoc] = useState([]);
    
    const [selTab, setSelTab] = useState("DETAILS");

    const [selGroup, setSelGroup] = useState(defaultEmployeeGroup);
    const [selLoc, setSelLoc] = useState([]);
    const [selTag, setSelTag] = useState([]);

    const [isCancel, setIsCancel] = useState(false);
    const [isSwitchTab, setIsSwitchTab] = useState(false);

    const [search, setSearch] = useState("");

    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const empGroupDat = await GetEmployeeGroup();

            setDs(empGroupDat);

            const clkDataTagDat = await GetClockDataTag();

            setDsMasterTag(clkDataTagDat);

            const tagDat = clkDataTagDat.map((row) => ({
                id: row.tag,
                label: row.label
            }));

            setDsTag(tagDat);

            const locationDat = await GetLocations();

            setDsMasterLoc(locationDat);

            const locDat = locationDat.map((row) => ({
                id: row.id,
                label: row.locationCode
            }));

            setDsLoc(locDat);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve list. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const renderList = () => {
        let dsTemp = ds.map((row) => row);

        if (search !== "") {
            dsTemp = dsTemp.filter((row) => {
                const srch = search.toLowerCase();

                return row.name.toLowerCase().indexOf(srch) > -1 || 
                    row.description.toLowerCase().indexOf(srch) > -1;
            });
        }

        const empGroupLst = dsTemp.map((row) => (
            <div key={ row.id } className="listing-row" onClick={() => { onSelection(row) }}>
                <strong>{ row.name }</strong>
                <span>{ row.description }</span>
            </div>
        ));

        setLst(empGroupLst);
    };

    const onFieldChange = (e, field) => {
        if (field === "NAME") {
            setSelGroup({
                ...selGroup,
                name: e
            });
        } else if (field === "DESCRIPTION") {
            setSelGroup({
                ...selGroup,
                description: e
            });
        } else if (field === "MANDATORY_QR_CODE") {
            setSelGroup({
                ...selGroup,
                mandatoryQRCode: e
            });
        } else if (field === "CLOCK_DATA_TAG") {
            const checks = dsMasterTag.filter((row) => {
                const i = e.findIndex((subrow) => subrow === row.tag);
                return i > -1;
            });

            setSelTag(e);

            setSelGroup({
                ...selGroup,
                tags: checks
            });
        } else if (field === "LOCATIONS") {
            const checks = dsMasterLoc.filter((row) => {
                const i = e.findIndex((subrow) => subrow === row.id);
                return i > -1;
            });

            setSelLoc(e);

            setSelGroup({
                ...selGroup,
                locations: checks
            });
        } else if (field === "OFFLINE_MODE") {
            setSelGroup({
                ...selGroup,
                enableOfflineMode: e
            });
        } else if (field === "SELF_CLOCK") {
            setSelGroup({
                ...selGroup,
                enableSelfClock: e
            });
        }
    };

    const onSelection = (e) => {
        const checkLoc = e.locations !== null ? e.locations : [];

        const checkLocs = checkLoc.map((row) => row.id);

        setSelLoc(checkLocs);

        const checkTag = e.tags !== null ? e.tags : [];

        const checkTags = checkTag.map((row) => row.tag);

        setSelTag(checkTags);

        setSelGroup(e);
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (isCancel || isSwitchTab) {
            if (isSwitchTab) {
                setIsSwitchTab(false);
            }

            return;
        }

        dispatch(setLoadingNow());

        try {
            if (selGroup.id === "-1") {
                const newDat = {
                    name: selGroup.name,
                    description: selGroup.description,
                    tags: selGroup.tags,
                    restrictLocation: selGroup.restrictLocation,
                    latitude: selGroup.latitude,
                    longitude: selGroup.longitude,
                    mandatoryQRCode: selGroup.mandatoryQRCode,
                    staticQRCode: selGroup.staticQRCode,
                    locations: selGroup.locations,
                    enableOfflineMode: selGroup.enableOfflineMode,
                    enableSelfClock: selGroup.enableSelfClock
                };

                await AddEmployeeGroup(newDat);
            } else {
                await UpdateEmployeeGroup(selGroup);
            }

            await refreshList();

            dispatch(showMsg({
                title: "Success",
                content: "Changes has been successfully saved!"
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to save changes. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onDelete = () => {
        if (selGroup.id === "-1") {
            return;
        }

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want to delete group ${ selGroup.name }?`,
            id: "DEL_EMP_GROUP",
            isPromptInput: true
        }));
    };

    useEffect(() => {
        (
            async () => {
                await refreshList();
            }
        )();
    }, []);

    useEffect(() => { renderList() }, [ds, search]);

    useEffect(() => {
        if (!isCancel) {
            return;
        }

        let selDat = defaultEmployeeGroup;

        if (selGroup.id !== "-1") {
            const i = ds.findIndex((row) => row.id === selGroup.id);

            if (i > -1) {
                selDat = ds[i];
            }
        }

        onSelection(selDat);

        setIsCancel(false);
    }, [isCancel]);

    useEffect(() => {
        if (!pageSel.msgResult) {
            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());
                
                try {
                    if (pageSel.msgId === "DEL_EMP_GROUP") {
                        await DeleteEmployeeGroup(selGroup.id);

                        await refreshList();

                        onSelection(defaultEmployeeGroup);

                        dispatch(hideMsg());
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ ex.message }`
                    }));
                } finally {
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    return (
        <>
            <strong className="title">Employee Group (Policy)</strong>
            <div className="panel-horizontal" style={{ position: "sticky" }}>
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={ refreshList }/>
                    <Button label="New" src="/img/add.svg" onClick={() => { onSelection(defaultEmployeeGroup) }}/>
                    <Button label="Delete" src="/img/remove.svg" onClick={ onDelete }/>
                </div>
                <div className="panel-inner panel-inner-end"></div>
            </div>
            <form className="side-panel" onSubmit={ onSave }>
                <div className="tab-container">
                    <button onClick={() => { setIsSwitchTab(true); setSelTab("DETAILS"); }}>Details</button>
                    <button onClick={() => { setIsSwitchTab(true); setSelTab("SECURITY"); }}>Security</button>
                    <button onClick={() => { setIsSwitchTab(true); setSelTab("TAGS"); }}>Clock Data Tag</button>
                    <button onClick={() => { setIsSwitchTab(true); setSelTab("LOCATIONS"); }}>Geo-Fence Locations</button>
                </div>
                {
                    selTab === "DETAILS" && (
                        <>
                            <div className="form-section">
                                <strong>Details</strong>
                            </div>
                            <div className="form-row" style={{ alignItems: "flex-start" }}>
                                <TextInput 
                                    label="Name" 
                                    required 
                                    value={ selGroup.name } 
                                    onChange={(e) => { onFieldChange(e, "NAME") }}
                                />
                                <TextInput 
                                    label="Description" 
                                    value={ selGroup.description } 
                                    onChange={(e) => { onFieldChange(e, "DESCRIPTION") }}
                                />
                            </div>
                            <div className="form-row">
                                <CheckBox 
                                    label="Mandatory QR Code Scan" 
                                    checked={ selGroup.mandatoryQRCode } 
                                    onChange={(e) => { onFieldChange(!selGroup.mandatoryQRCode, "MANDATORY_QR_CODE") }}
                                />
                            </div>
                        </>
                    )
                }
                {
                    selTab === "TAGS" && (
                        <>
                            <div className="form-section">
                                <strong>Clock Data Tags</strong>
                            </div>
                            <div className="form-row">
                                <span>Select tags to be available to group assignee.</span>
                            </div>
                            <div className="form-row">
                                <CheckBoxCollection style={{ height: "44vh" }} data={ dsTag } checked={ selTag } onSelectionChange={(e) => { onFieldChange(e, "CLOCK_DATA_TAG") }}/>
                            </div>
                        </>
                    )
                }
                {
                    selTab === "LOCATIONS" && (
                        <>
                            <div className="form-section">
                                <strong>Geo-Fence Locations</strong>
                            </div>
                            <div className="form-row">
                                <span>Select location. If none selected, no geo-fencing/location restriction to perform clocking.</span>
                            </div>
                            <div className="form-row">
                                <CheckBoxCollection style={{ height: "44vh" }} data={ dsLoc } checked={ selLoc } onSelectionChange={(e) => { onFieldChange(e, "LOCATIONS") }}/>
                            </div>
                        </>
                    )
                }
                {
                    selTab === "SECURITY" && (
                        <>
                            <div className="form-section">
                                <strong>Offline</strong>
                            </div>
                            <div className="form-row">
                                <CheckBox 
                                    label="Allow offline clocking" 
                                    checked={ selGroup.enableOfflineMode } 
                                    onChange={(e) => { onFieldChange(!selGroup.enableOfflineMode, "OFFLINE_MODE") }}
                                />
                            </div>
                            <div className="form-row">
                                <span>If this option enabled, user able to clock as usual without internet connection.</span>
                            </div>
                            <div className="form-row">
                                <CheckBox 
                                    label="Allow self clocking" 
                                    checked={ selGroup.enableSelfClock } 
                                    onChange={(e) => { onFieldChange(!selGroup.enableSelfClock, "SELF_CLOCK") }}
                                />
                            </div>
                            <div className="form-row">
                                <span>If this option enabled, user able to clock as usual without supervisor on-behalf.</span>
                            </div>
                        </>
                    )
                }
                <div className="form-row form-row-border-top">
                    <div></div>
                    <div></div>
                    <div className="form-row" style={{ padding: 0 }}>
                        <SaveButton /> 
                        <CancelButton onClick={() => { setIsCancel(true) }}/>
                    </div>
                </div>
            </form>
            <div className="listing">
                <input className="listing-search-box" type="text" placeholder="search" onChange={(e) => { setSearch(e.target.value) }}/>
                <div>
                    { lst }
                </div>
            </div>
            <Help sectionName="Employee Group" show={ pageSel.showManual }>
                <h3>What is Employee Group</h3>
                <p>
                    Employee group is a set of policy, including geo-fencing (where employee allowed to perform clocking), 
                    clock data tag available and QR code scanning mandate.
                </p>
                <h3>Creating New Employee Group</h3>
                <ul>
                    <li>Click on <strong>"New"</strong> button.</li>
                    <li>Enter all particulars.</li>
                    <li>Click on <strong>"Clock Data Tag"</strong> to turn to clock data tag policy.</li>
                    <li>Select clock data tag to be available to assignee.</li>
                    <li>Click on <strong>"Geo-Fencing Locations"</strong> to turn to geo-fencing policy. To define geo-fencing locations, go to <strong>"Location"</strong> page.</li>
                    <li>Click <strong>"Save"</strong> button to save changes.</li>
                </ul>
                <h3>Deleting Employee Group</h3>
                <ul>
                    <li>To delete employee group, simply select employee group to delete.</li>
                    <li>Then, click <strong>"Delete"</strong> button.</li>
                    <li>When confirmation prompt, click on <strong>yes</strong> to delete and <strong>no</strong> to cancel.</li>
                </ul>
                <h3>Defining New Geo-Fencing Location</h3>
                <ul>
                    <li>To define new geo-fencing location, go to <strong>"Locations"</strong> page.</li>
                </ul>
                <h3>Create New Clock Data Tag</h3>
                <ul>
                    <li>To create new clock data tag, go to <strong>"Clock Data Tag"</strong> page.</li>
                </ul>
                <h3>Offline Mode</h3>
                <p>Offline mode can be enabled from the "Security" tab. This option allows iFlexi Clock users in your organization to perform 
                    clocking while offline. <strong>Enabling this feature has a downside, as users can manipulate the device clock time.</strong>
                </p>
                <ul>
                    <li><strong>Allow users to perform supervised clocking while offline</strong>
                        <ul>
                            <li>Go to the <strong>"Security"</strong> tab and enable <strong>"Allow offline clocking"</strong>.</li>
                            <li>Disable <strong>"Allow self clocking"</strong>.</li>
                            <li>With this combination, a supervisor is required to scan the generated QR code on the subordinate mobile app.</li>
                        </ul>
                    </li>
                    <li><strong>Allow users to perform unsupervised clocking while offline</strong>
                        <ul>
                            <li>Go to the <strong>"Security"</strong> tab and enable <strong>"Allow offline clocking"</strong>.</li>
                            <li>Enable <strong>"Allow self clocking"</strong>.</li>
                            <li>With this combination, iFlexi Clock users under the selected employee group can perform clocking as usual.</li>
                            <li>Please be aware that by having this combination, users can manipulate the device clock time.</li>
                        </ul>
                    </li>
                </ul>
            </Help>
        </>
    );
}

const defaultEmployeeGroup = {
    id: "-1",
    name: "",
    description: "",
    tags: [],
    restrictLocation: false,
    latitude: "",
    longitude: "",
    mandatoryQRCode: false,
    staticQRCode: "",
    locations: [],
    enableOfflineMode: false,
    enableSelfClock: false
};
import dbList from "../DBList.json";

const {
    api_server,
    location
} = dbList.server;

const url = api_server + location;

export async function GetLocations() {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function AddLocation(ALocation) {
    const httpRes = await fetch(url, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(ALocation)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function UpdateLocation(ALocation) {
    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(ALocation)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function DeleteLocation(AId) {
    const httpRes = await fetch(`${ url }/${ AId }`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}
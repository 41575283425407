import dbList from "../DBList.json";

const {
    api_server,
    general_setup
} = dbList.server;

const url = api_server + general_setup;

export async function GetGeneralSetup() {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function UpdateGeneralSetup(AGen) {
    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AGen)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}
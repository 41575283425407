import {
    useEffect,
    useState
} from "react";

import {
    useDispatch,
    useSelector
} from "react-redux";

import {
    ComboBox,
    Button,
    TextInput,
    SaveButton,
    CancelButton,
    ActionButton,
    TextArea
} from "../components/Input";

import {
    showMsg,
    hideMsg,
    setLoadingNow,
    setLoadingComplete,
    pageSelector
} from "../store/pageSlice";

import { 
    GetExportConf,
    AddExportConf,
    UpdateExportConf,
    DeleteExportConf 
} from "../api/exportConfig";

import { ExportClockData } from "../api/exportClockData";

import { 
    FormatCompDate, 
    PadValue
} from "../miscfunction/DateTimeFormat";

import Help from "./Help";

import "../App.css";
import "./ExportTemplate.css";

export default function ExportTemplate() {
    const dispatch = useDispatch();

    const pageSel = useSelector(pageSelector);

    const [ds, setDs] = useState([]);

    const [optConfig, setOptConfig] = useState([]);

    const [lstColumn, setLstColumn] = useState([]);

    const [lstColDef, setLstColDef] = useState([]);

    const [colEnum, setColEnum] = useState([]);

    const [selExpConf, setSelExpConf] = useState(defaultExpTemplate);

    const [isCancel, setIsCancel] = useState(false);
    
    const [isCancelColDef, setIsCancelColDef] = useState(false);
    const [selColDef, setSelColDef] = useState(defaultColumnDef);
    const [showColDef, setShowColDef] = useState(false);

    const [isAddDef, setIsAddDef] = useState(false);
    const [addDef, setAddDef] = useState("");
    const [updDef, setUpdDef] = useState(null);

    const [isAddColDef, setIsAddColDef] = useState(false);
    const [isDelColDef, setIsDelColDef] = useState(false);

    const [dtPrevStart, setDtPrevStart] = useState(FormatCompDate(new Date()));
    const [dtPrevEnd, setDtPrevEnd] = useState(FormatCompDate(new Date()));

    const [showPrevParam, setShowPrevParam] = useState(false);

    const [prevContent, setPrevContent] = useState("");

    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const res = await GetExportConf();

            setDs(res);

            const opts = res.map((row) => (
                <option value={ row.id }>{ row.name }</option>
            ));

            setOptConfig(opts);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to fetch export configurations. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onSelectConf = (e) => {
        const conf = ds.filter((row) => row.id === e);

        if (conf.length === 0) {
            setSelExpConf(defaultExpTemplate);

            setLstColumn([]);

            setLstColDef([]);

            return;
        }

        setSelExpConf(conf[0]);

        renderColDef(conf[0].columnDefinition);
    };

    const renderColDef = (defs) => {
        const colDefs = defs;

        const lst = colDefs.map((row) => (
            <div 
                key={ row.definitionKeyName } 
                className="listing-row" 
                onClick={() => { setSelColDef(row) }}
            >
                <strong>{ row.definitionKeyName }</strong>
                <span>{ row.columnName }</span>
            </div>
        ));

        setLstColumn(lst);

        const lstDefs = colDefs.map((row) => (
            <div 
                key={ row.definitionKeyName } 
                className="listing-row listing-row-col-def" 
                onClick={() => { setSelColDef(row) }}
            >
                <div>
                    <strong>{ row.definitionKeyName }</strong>
                    <span>{ row.columnName }</span>
                </div>
                <ActionButton 
                    src="/img/add_white.svg" 
                    onClick={() => { 
                        setIsAddDef(true);
                        setAddDef(`%{${ row.definitionKeyName }}%`);
                    }}
                />
            </div>
        ));

        setLstColDef(lstDefs);
    };

    const onFieldChange = (e, field) => {
        if (field === "NAME") {
            setSelExpConf({
                ...selExpConf,
                name: e
            });
        } else if (field === "EXPORT_FORMAT") {
            setSelExpConf({
                ...selExpConf,
                exportFormat: e
            });
        }
    };

    const onAppendExportFormat = (e) => {
        const expFormat = selExpConf.exportFormat !== undefined ? selExpConf.exportFormat : "";

        setSelExpConf({
            ...selExpConf,
            exportFormat: expFormat + e
        });
    };

    const onDefFieldChange = (e, field) => {
        if (field === "DEFINITION_KEY_NAME") {
            setSelColDef({
                ...selColDef,
                definitionKeyName: e
            });
        } else if (field === "COLUMN_NAME") {
            let sFormat = "%{val}%";
            let sDataType = "2";

            const col = columns.find((row) => row.value === e);

            sDataType = col.dataType;

            if (e === "ClockTime") {
                sFormat = "%{yyyy-MM-dd HH:mm}%";
            }   

            setSelColDef({
                ...selColDef,
                columnName: e,
                outputFormat: sFormat,
                dataType: sDataType
            });
        } else if (field === "OUTPUT_FORMAT") {
            setSelColDef({
                ...selColDef,
                outputFormat: e
            });
        } else if (field === "DATA_TYPE") {
            setSelColDef({
                ...selColDef,
                dataType: e
            });
        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (isAddDef || showColDef || isCancel || showPrevParam) {
            setIsAddDef(false);

            setIsCancel(false);

            return;
        }

        dispatch(setLoadingNow());

        try {
            if (selExpConf.id === "-1") {
                const newDat = {
                    name: selExpConf.name,
                    exportFormat: selExpConf.exportFormat,
                    columnDefinition: selExpConf.columnDefinition
                };

                await AddExportConf(newDat);
            } else {
                await UpdateExportConf(selExpConf);
            }

            await refreshList();

            dispatch(showMsg({
                title: "Success",
                content: "Changes successfully saved."
            }));      
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to save chagnes. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onSaveColDef = async (e) => {
        e.preventDefault();

        if (isCancelColDef || isAddColDef || isDelColDef) {
            setIsCancelColDef(false);

            setIsAddColDef(false);

            setIsDelColDef(false);

            return;
        }

        try {
            const defs = selExpConf.columnDefinition.map((row) => row);

            const i = defs.findIndex((row) => row.definitionKeyName === selColDef.definitionKeyName);

            if (i < 0) {
                defs.push(selColDef);
            } else {
                defs.splice(i, 1, selColDef);
            }

            setUpdDef(defs);

            dispatch(showMsg({
                title: "Success",
                content: i < 0 ? "Column definition has been added." : "Column definition has been updated."
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable save column definition. ${ ex.message }`
            }));
        }
    };

    const onDelete = () => {
        if (selExpConf.id === "-1") {
            return;
        }

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want to delete ${ selExpConf.name }?`,
            id: "DEL_EXP_CONF",
            isPromptInput: true
        }));
    };

    const onPreviewData = async (e) => {
        e.preventDefault();

        dispatch(setLoadingNow());

        try {
            const res = await ExportClockData({
                id: "-1",
                dateStart: dtPrevStart,
                dateEnd: dtPrevEnd,
                timezoneOffset: new Date().getTimezoneOffset(),
                config: selExpConf
            });

            setShowPrevParam(false);

            setPrevContent(res);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Preview failed. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const getSampleDate = () => {
        const dtToday = new Date();
        return `${ dtToday.getFullYear() }${ PadValue(dtToday.getMonth() + 1, 10, 2) }${ PadValue(dtToday.getDate(), 10, 2) }`
    };

    const getSampleDate2 = () => {
        const dtToday = new Date();
        return `${ PadValue(dtToday.getDate(), 10, 2) }-${ PadValue(dtToday.getMonth() + 1, 10, 2) }-${ dtToday.getFullYear() }`
    };

    useEffect(() => {
        const enums = columns.map((row) => (
            <option value={ row.value }>{ row.label }</option>
        ));

        setColEnum(enums);

        (
            async () => {
                await refreshList();
            }
        )();
    }, []);

    useEffect(() => {
        if (addDef === "") {
            return;
        }

        onAppendExportFormat(addDef);
    }, [addDef]);

    useEffect(() => {
        if (!pageSel.msgResult) {
            dispatch(hideMsg());
            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());

                try {
                    if (pageSel.msgId === "DEL_EXP_CONF") {
                        await DeleteExportConf(selExpConf.id);

                        await refreshList();

                        onSelectConf("-1");
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ ex.message }`
                    }));
                } finally {
                    dispatch(hideMsg());
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    useEffect(() => {
        if (!isCancelColDef) {
            return;
        }

        const { definitionKeyName } = selColDef;

        const defs = selExpConf.columnDefinition.map((row) => row);

        const i = defs.findIndex((row) => row.definitionKey === definitionKeyName);

        if (i < 0) {
            setSelColDef(defaultColumnDef);
        } else {
            setSelColDef(defs[i]);
        }
    }, [isCancelColDef]);

    useEffect(() => {
        if (updDef === null) {
            return;
        }

        setSelExpConf({
            ...selExpConf,
            columnDefinition: updDef
        });

        renderColDef(updDef);

        setUpdDef(null);
    }, [updDef]);

    useEffect(() => {
        if (!isAddColDef) {
            return;
        }

        setSelColDef(defaultColumnDef);
    }, [isAddColDef]);

    useEffect(() => {
        if (!isDelColDef) {
            return;
        }

        const defs = selExpConf.columnDefinition.map((row) => row);

        const i = defs.findIndex((row) => row.definitionKeyName === selColDef.definitionKeyName);

        if (i < 0) {
            setSelColDef(defaultColumnDef);
        } else {
            defs.splice(i, 1);
        }

        setUpdDef(defs);

        renderColDef(defs);
    }, [isDelColDef]);

    useEffect(() => {
        if (showColDef) {
            setSelColDef(defaultColumnDef);
        }
    }, [showColDef]);

    return (
        <>
            <strong className="title">Export Template</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg"/>
                    <div className="template-combobox">
                        <ComboBox label="Templates" horizontal opts={ optConfig } value={ selExpConf.id } onChange={(e) => { onSelectConf(e.target.value) } } />
                    </div>
                    <Button label="New" src="/img/add.svg" onClick={() => { onSelectConf("-1") }}/>
                    <Button label="Delete" src="/img/remove.svg" onClick={ onDelete }/>
                </div>
            </div>
            <form className="panel panel-export-template" onSubmit={ onSave }>
                <div className="form-section">
                    <strong>Details</strong>
                </div>
                <div className="form-row">
                    <TextInput label="Template Name" required value={ selExpConf.name } onChange={(e) => { onFieldChange(e, "NAME") }}/>
                </div>
                <div className="form-row form-row-column-designer export-designer-panel">
                    <div className="column-designer">
                        <div className="column-designer-button-container export-designer-button-container">
                            <strong>Columns</strong>
                            <Button label="Open Column Definition" src="/img/construction.svg" onClick={() => { setShowColDef(true) }}/>
                        </div>
                        { lstColDef }
                    </div>
                    <div>
                        <div className="column-designer-button-container">
                            <Button label="Preview" src="/img/play.svg" onClick={() => { setShowPrevParam(true) }}/>
                        </div>
                        <div className="form-row">
                            <span className="export-format-textarea">
                                <TextArea label="Export Format" required value={ selExpConf.exportFormat } onChange={(e) => { onFieldChange(e, "EXPORT_FORMAT") }}/>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-row form-row-border-top">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="form-row" style={{ padding: 0 }}>
                        <SaveButton />
                        <CancelButton onClick={() => { setIsCancel(true) }}/>
                    </div>
                </div>
            </form>
            <div className={ showColDef ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "80vw" }}>
                    <div className="dialog-header">
                        <span>Column Definition</span>
                        <button onClick={() => { setShowColDef(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row form-row-column-designer">
                            <div className="column-designer">
                                { lstColumn }
                            </div>
                            <form onSubmit={ onSaveColDef }>
                                <div className="column-designer-button-container">
                                    <ActionButton src="/img/add_white.svg" onClick={() => { setIsAddColDef(true) }}/>
                                    <ActionButton src="/img/remove_white.svg" onClick={() => { setIsDelColDef(true) }}/>
                                </div>
                                <div className="form-row">
                                    <TextInput 
                                        label="Key Name" 
                                        required 
                                        value={ selColDef.definitionKeyName }
                                        onChange={(e) => { onDefFieldChange(e, "DEFINITION_KEY_NAME") }}
                                    />
                                    <ComboBox 
                                        label="Column" 
                                        value={ selColDef.columnName } 
                                        opts={ colEnum } 
                                        required
                                        onChange={(e) => { onDefFieldChange(e.target.value, "COLUMN_NAME") }}
                                    />
                                </div>
                                <div className="form-row">
                                    <TextInput 
                                        label="Output Format" 
                                        required 
                                        value={ selColDef.outputFormat }
                                        onChange={(e) => { onDefFieldChange(e, "OUTPUT_FORMAT") }}
                                    />
                                    <div></div>
                                </div>
                                <div className="form-row form-row-border-top">
                                    <div></div>
                                    <div></div>
                                    <Button label="Add" src="/img/save_white.svg" type="submit" />
                                    <CancelButton  onClick={() => { setIsCancelColDef(true) }}/>
                                </div>
                            </form>
                        </div>                        
                    </div>
                </div>
            </div>
            <div className={ showPrevParam ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "60vw" }}>
                    <div className="dialog-header">
                        <span>Preview</span>
                        <button onClick={() => { setShowPrevParam(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ onPreviewData }>
                        <div className="form-row">
                            <strong>Date</strong>
                        </div>
                        <div className="form-row">
                            <TextInput label="Start" type="datetime-local" required value={ dtPrevStart } onChange={(e) => { setDtPrevStart(e) }}/>
                            <TextInput label="End" type="datetime-local" required value={ dtPrevEnd } onChange={(e) => { setDtPrevEnd(e) }}/>
                        </div>
                        <div className="form-row form-row-border-top">
                            <div></div>
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button type="submit" label="Apply" src="/img/check.svg" />
                                <CancelButton onClick={() => { setShowPrevParam(false) }} />
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
            <div className={ prevContent !== "" ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "90vw" }}>
                    <div className="dialog-header">
                        <span>Preview</span>
                        <button onClick={() => { setPrevContent("") }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <div className="dialog-body">
                        <pre>{ prevContent }</pre>
                    </div>
                </div>
            </div>
            <Help sectionName="Export Template">
                <h3>What is Export Template</h3>
                <p>Export template is template use to define structure of clock data export to text file. This will provide
                    flexibility to in-house and 3rd-party system compatibility.
                </p>
                <h3>Create New Export Template</h3>
                <ul>
                    <li>To create new export template, click on <strong>"New"</strong> button.</li>
                    <li>Enter template name.</li>
                    <li>Before you can set export format, first, you must create column definition.</li>
                    <li>Column definition allow you to use same column with different output format.</li>
                    <li>Click on <strong>"Open Column Definition"</strong> button.</li>
                    <li>Create column definitions.</li>
                    <li>Click on <strong>"Add"</strong> button.</li>
                    <li>Click on <strong>X</strong> button to close column definition designer.</li>
                    <li>Add column definiton will be re-populate and appear in column definition list.</li>
                    <li>Click on <strong>+</strong> button to respective column definition to export format.</li>
                    <li>You may add adjustment to export format, such as prefix, spacing in between or comma.</li>
                    <li>Click on <strong>"Save"</strong> button to save changes.</li>
                </ul>
                <h3>Export filename</h3>
                <ul>
                    <li>By default, system will export clock data as .txt file.</li>
                    <li>
                        To make iFlexi Clock export file with custom file extension, include file extension at the end of 
                        template name.
                    </li>
                    <li>As an example, Export.csv or Export.log or etc.</li>
                </ul>
                <h3>Manage Column Definition</h3>
                <ul>
                    <li>Column definition can be access from <strong>"Open Column Definition"</strong> button.</li>
                    <li>To create new column definition, click on <strong>+</strong> button.</li>
                    <li>To edit, click on desired definition.</li>
                    <li>To delete, select desier definition and click on <strong>-</strong>.</li>
                    <li>Any changes will be saved on the export template main screen.</li>
                </ul>
                <h3>Column Definition - Output Format</h3>
                <ul>
                    <li>The <strong>"Output Format"</strong> field allow you to customize output formatting to the template when clock data being 
                    exported.
                    </li>
                    <li>In order for the respective column to display correct value, you must not remove the sign <strong>{ "%{val}%" }</strong> .</li>
                    <li>To have prefix, you may add any text before <strong>{ "%{val}%" }</strong> .</li>
                    <li>As an example EmpNo column is selected with output format, <strong>{ "ABC-%{val}%" }</strong>; this will display <strong>{ "ABC-MFH001" }</strong> .</li>
                    <li>To have postfix, you may add any text after <strong>{ "%{val}%" }</strong>. This will behave same as prefix.</li>
                    <li>You may also have both prefix and postfix.</li>
                </ul>
                <h3>Column Definition - Output Format for Date and Time</h3>
                <ul>
                    <li>Output format for date and time can only work if column <strong>"Clock Time"</strong> is selected.</li>
                    <li>By default, the output format will automatically assigned in such format, <strong>{ "%{yyyyMMdd}%" }</strong>.</li>
                    <li>By means, it will display year month then date; as an example, <strong>{ getSampleDate() }</strong> .</li>
                    <li>To have your own formatting, you may enter these format specifiers within this <strong>{ "%{}%" }</strong> pattern in output format
                        <ul>
                            <li><strong>y</strong> - Year.</li>
                            <li><strong>M</strong> - Month.</li>
                            <li><strong>d</strong> - Date/Day; enter more than two times 'd' may return day.</li>
                            <li><strong>H</strong> - Hour.</li>
                            <li><strong>m</strong> - Minute.</li>
                            <li><strong>s</strong> - Second.</li>
                        </ul>
                    </li>
                    <li>As an example, to display date time in such pattern, <strong>{ getSampleDate2() }</strong>, 
                    you may need to enter such pattern <strong>{ "%{dd-MM-yyyy}%" }</strong> .</li>
                </ul>
            </Help>
        </>
    );
}

const columns = [
    { label: "Employee No.", value: "EmpNo", dataType: "2" },
    { label: "Name", value: "Name", dataType: "2" },
    { label: "Clock Time", value: "ClockTime", dataType: "3" },
    { label: "Location", value: "Location", dataType: "2" },
    { label: "Longitude", value: "Longitude", dataType: "2" },
    { label: "Latitude", value: "Latitude", dataType: "2" },
    { label: "Clock Data Tag", value: "ClockDataTag", dataType: "2" },
    { label: "Supervisor", value: "Supervisor", dataType: "2" },
    { label: "Remark", value: "Remark", dataType: "2" },
    { label: "QR Code", value: "QRCode", dataType: "2" }
];

const defaultExpTemplate = {
    id: "-1",
    name: "",
    exportFormat: "",
    columnDefinition: []
};

const defaultColumnDef = {
    definitionKeyName: "",
    columnName: "EmpNo",
    outputFormat: "%{val}%",
    dataType: "2"
}; 
import dbList from "../DBList.json";

const {
    api_server,
    employee_master
} = dbList.server;

const url = api_server + employee_master;

export async function GetEmployeeMaster() {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function AddEmployeeMaster(AEmployee) {
    const httpRes = await fetch(url, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AEmployee)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function ResetRequestToken(AEmpNo) {
    const httpRes = await fetch(`${ url }/resetreqtoken/${ AEmpNo }`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.text();
}

export async function UpdateEmployeeMaster(AEmployee) {
    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: { 
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AEmployee)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function DeleteEmployeeMaster(AId) {
    const httpRes = await fetch(`${ url }/${ AId }`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function UploadEmployeeMaster(AEmployees) {
    const httpRes = await fetch(`${ url }/upload`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AEmployees)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function ResetRegisteredDevice(AReq) {
    const httpRes = await fetch(`${ url }/reset/device`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: { 
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AReq)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function GetDeviceResetHistory(AEmpNo) {
    const httpRes = await fetch(`${ url }/history/devicereset/${ AEmpNo }`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    if (httpRes.status === 204) {
        return null;
    }

    return await httpRes.json();
}

export async function GetRequestToken(AEmpNo) {
    const httpRes = await fetch(`${ url }/getreqtoken/${ AEmpNo }`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    if (httpRes.status === 204) {
        return null;
    }

    return await httpRes.text();
}
import { 
    useState,
    useEffect 
} from "react";

import { 
    useDispatch, 
    useSelector
} from "react-redux";

import { 
    setShowManual, 
    pageSelector
} from "../store/pageSlice";

export default function Help(props) {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);

    return (
        <div className={ pageSel.showManual ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
            <div className="dialog" style={{ width: "60vw", height: "80vh" }}>
                <div className="dialog-header">
                    <span>Help - { props.sectionName }</span>
                    <button onClick={() => { dispatch(setShowManual(false)) }}>
                        <img src="/img/close_black.svg" />
                    </button>
                </div>
                <div className="dialog-body-free">
                    { props.children }
                </div>
            </div>
        </div>
    );
}
import dbList from "../DBList.json";

const {
    api_server,
    export_clock_data
} = dbList.server;

const url = api_server + export_clock_data;

export async function ExportClockData(AParams) {
    const dtStart = new Date(AParams.dateStart);
    const dtEnd = new Date(AParams.dateEnd);

    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify({
            ...AParams,
            dateStart: dtStart,
            dateEnd: dtEnd
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.text();
}
import { 
    useState, 
    useEffect
} from "react";

import { 
    useDispatch, 
    useSelector
} from "react-redux";

import { 
    Button,
    ActionButton,
    TextInput,
    ComboBox,
    CheckBox,
    SaveButton,
    CancelButton
} from "../components/Input";

import { FormatCompDate } from "../miscfunction/DateTimeFormat";

import { 
    showMsg,
    hideMsg,
    setShowManual,
    setLoadingNow,
    setLoadingComplete,
    pageSelector 
} from "../store/pageSlice";

import { 
    GetEmployeeMaster,
    AddEmployeeMaster,
    UpdateEmployeeMaster,
    DeleteEmployeeMaster,
    ResetRequestToken,
    ResetRegisteredDevice,
    UploadEmployeeMaster,
    GetDeviceResetHistory,
    GetRequestToken
} from "../api/employeeMaster";

import { GetEmployeeGroup } from "../api/employeeGroup";

import Help from "./Help";

import AutoTable from "../components/AutoTable";
import "../components/AutoTable.css";

import "../App.css";

import "./EmployeeMaster.css";

export default function EmployeeMaster() {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);

    const [ds, setDs] = useState([]);

    const [optEmpGroup, setOptEmpGroup] = useState([]);

    const [tblRows, setTblRows] = useState([]);
    const [showEditor, setShowEditor] = useState(false);
    const [showImport, setShowImport] = useState(false);
    const [showDevManager, setShowDevManager] = useState(false);

    const [srchSel, setSrchSel] = useState(false);
    const [srchEmpNo, setSrchEmpNo] = useState("");
    const [srchName, setSrchName] = useState("");
    const [srchDept, setSrchDept] = useState("");
    const [srchEmpGroup, setSrchEmpGroup] = useState("");
    const [srchHireDate, setSrchHireDate] = useState("");

    const [selEmployee, setSelEmployee] = useState(defaultEmployee);

    const [delSingleEmp, setDelSingleEmp] = useState(null);

    const [isCancel, setIsCancel] = useState(false);
    const [isResetPwd, setIsResetPwd] = useState(false);
    const [isViewDevice, setIsViewDevice] = useState(false);

    const [selCsv, setSelCsv] = useState(null);
    const [selFile, setSelFile] = useState(null);

    const [empTokens, setEmpTokens] = useState([]);

    const [empDevs, setEmpDevs] = useState([]);
    const [srchDev, setSrchDev] = useState("");

    const [showResetDevice, setShowResetDevice] = useState(false);
    const [remarkReset, setRemarkReset] = useState("");

    const [deviceResetLog, setDeviceResetLog] = useState([]);
    const [showDeviceResetLog, setShowDeviceResetLog] = useState(false);
    
    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const res = await GetEmployeeMaster();

            const resDs = res.map((row) => ({
                ...row,
                sel: false
            }));

            setDs(resDs);

            const resEmpGroup = await GetEmployeeGroup();

            const optsEmpGroup = resEmpGroup.map((row) => (
                <option key={ row.name } value={ row.name }>{ row.name }</option>
            ));

            setOptEmpGroup(optsEmpGroup);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve employee master listing. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const renderList = () => {
        let dsTemp = ds.map((row) => row);

        setTblRows([]);

        for (let i = 0; i < 6; i++) {
            if (i === 1 && srchEmpNo !== "") {
                dsTemp = dsTemp.filter((row) => row.empNo.toLowerCase().indexOf(srchEmpNo) > -1);
            } else if (i === 2 && srchName !== "") {
                dsTemp = dsTemp.filter((row) => row.name.toLowerCase().indexOf(srchName) > -1);
            } else if (i === 3 && srchDept !== "") {
                dsTemp = dsTemp.filter((row) => row.department.toLowerCase().indexOf(srchDept) > -1);
            } else if (i === 4 && srchEmpGroup !== "") {
                dsTemp = dsTemp.filter((row) => row.employeeGroup.toLowerCase().indexOf(srchEmpGroup) > -1);
            } else if (i === 5 && srchHireDate !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const dt = new Date(row.hireDate).toLocaleString().toLowerCase();
                    return dt.indexOf(srchHireDate) > -1;
                });
            } else if (i === 0 && srchSel) {
                dsTemp = dsTemp.filter((row) => row.sel === true);
            }
        }

        const lst = dsTemp.map((row) => {
            return (
                <EmployeeRow 
                    key={ row.id } 
                    data={ row } 
                    onEdit={ onEdit } 
                    onDelete={ onDeleteSingle }
                    onSelectChange={ onSelectChange }
                    selected={ row.sel }
                />
            );
        });

        setTblRows(lst);
    };

    const renderDevList = () => {
        let dsTemp = ds.map((row) => row);

        dsTemp = dsTemp.filter((row) => (row.device !== undefined && row.device !== null) || (row.deviceHash !== null && row.deviceHash !== ""));

        if (srchDev !== "") {
            dsTemp = dsTemp.filter((row) => {
                const sEmpNo = row.empNo.toLowerCase();
                const sName = row.name.toLowerCase();
                
                const sDeviceGuid = row.device !== null ? row.device.deviceGUID.toLowerCase() : "";
                const sModelName = row.device !== null ? row.device.modelName.toLowerCase() : "";
                const sManufacturer = row.device !== null ? row.device.manufacturer.toLowerCase() : "";
                const sDeviceName = row.device !== null ? row.device.deviceName.toLowerCase() : "";

                const srchKey = srchDev.toLowerCase();

                return sEmpNo.indexOf(srchKey) > -1 || sName.indexOf(srchKey) > -1 ||
                    sDeviceGuid.indexOf(srchKey) > -1 || sModelName.indexOf(srchKey) > -1 ||
                    sManufacturer.indexOf(srchKey) > -1 || sDeviceName.indexOf(srchKey) > -1;
            });
        }

        const lst = dsTemp.map((row) => (
            <div 
                key={ row.id }
                className="listing-row"
                onClick={() => { setSelEmployee(row) }}
            >
                {
                    (row.device !== undefined && row.device !== null) && (
                        <>
                        <strong>{ row.device.deviceName }</strong>
                        </>
                    )
                }
                {
                    (row.device === null || row.device === undefined) && (
                        <>
                        <strong>Legacy Version</strong>
                        </>
                    )
                }
                <span>{ row.empNo } - { row.name }</span>
            </div>
        ));

        setEmpDevs(lst);
    };

    const onSearch = (i, e) => {
        switch(i) {
            case 0:
                setSrchSel(e);
                break;
            case 1:
                setSrchEmpNo(e.toLowerCase());
                break;
            case 2:
                setSrchName(e.toLowerCase());
                break;
            case 3:
                setSrchDept(e.toLowerCase());
                break;
            case 4:
                setSrchEmpGroup(e.toLowerCase());
                break;
            case 5:
                setSrchHireDate(e.toLowerCase());
                break;
        }
    };

    const onSelectChange = (e) => {
        const dsTemp = ds.map((row) => {
            if (row.id !== e) {
                return row;
            }

            return ({
                ...row,
                sel: !row.sel
            });
        });

        setDs(dsTemp);
    };

    const onAddNew = () => {
        setSelEmployee(defaultEmployee);
        setShowEditor(true);
    };

    const onEdit = async (e) => {
        let selEmpData = e;

        try {
            const reqToken = await GetRequestToken(e.empNo);

            if (reqToken !== null) {
                selEmpData = {
                    ...selEmpData,
                    requestToken: reqToken
                };
            }
        } catch {
        } finally {
            setSelEmployee(selEmpData);
        }

        setShowEditor(true);
    };

    const onDelete = () => {
        const dsTemp = ds.filter((row) => row.sel);

        if (dsTemp.length === 0) {
            return;
        }

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want to delete ${ dsTemp.length } selected employees?`,
            id: "DEL_SEL_EMP",
            isPromptInput: true
        }));
    };

    const onDeleteRows = async () => {
        const dsSel = ds.filter((row) => row.sel);

        try {
            for (let i = 0; i < dsSel.length; i++) {
                const row = dsSel[i];
                await DeleteEmployeeMaster(row.id);
            }
        } catch {

        } finally {
            await refreshList();
        }
    };

    const onDeleteSingle = (e) => {
        const emp = ds.filter((row) => row.id === e);

        if (emp === null) {
            return;
        }

        setDelSingleEmp(e);
        
        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want to delete employee ${ emp[0].empNo } - ${ emp[0].name }?`,
            id: "DEL_SINGLE_EMP",
            isPromptInput: true
        }));
    };

    const onSave = async (e) => {
        e.preventDefault();

        try {
            if (isCancel || isResetPwd || isViewDevice) {
                if (isCancel)
                    setIsCancel(false);

                if (isResetPwd)
                    setIsResetPwd(false);

                return;
            }

            let newDat = {
                empNo: selEmployee.empNo,
                name: selEmployee.name,
                firstName: selEmployee.firstName,
                lastName: selEmployee.lastName,
                email: selEmployee.email,
                department: selEmployee.department,
                hireDate: new Date(selEmployee.hireDate),
                isSupervisor: selEmployee.isSupervisor,
                employeeGroup: selEmployee.employeeGroup,
                noOnBehalf: selEmployee.noOnBehalf,
                noTeamManager: selEmployee.noTeamManager
            };

            dispatch(setLoadingNow());

            if (selEmployee.id === "-1") {
                await AddEmployeeMaster(newDat);
            } else {
                newDat = {
                    ...newDat,
                    id: selEmployee.id
                };

                if (selEmployee.resignDate !== undefined) {
                    newDat = {
                        ...newDat,
                        resignDate: new Date(selEmployee.resignDate)
                    };
                }

                await UpdateEmployeeMaster(newDat);
            }

            await refreshList();

            setSelEmployee(defaultEmployee);

            setShowEditor(false);

            dispatch(showMsg({
                title: "Success",
                content: "Changes has been successfully saved!"
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to save changes. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onFieldChange = (e, field) => {
        if (field === "EMPNO") {
            setSelEmployee({
                ...selEmployee,
                empNo: e
            });
        } else if (field === "NAME") {
            setSelEmployee({
                ...selEmployee,
                name: e
            });
        } else if (field === "FIRST_NAME") {
            setSelEmployee({
                ...selEmployee,
                firstName: e
            });
        } else if (field === "LAST_NAME") {
            setSelEmployee({
                ...selEmployee,
                lastName: e
            });
        } else if (field === "EMAIL") {
            setSelEmployee({
                ...selEmployee,
                email: e
            });
        } else if (field === "DEPARTMENT") {
            setSelEmployee({
                ...selEmployee,
                department: e
            });
        } else if (field === "HIRE_DATE") {
            setSelEmployee({
                ...selEmployee,
                hireDate: e
            });
        } else if (field === "RESIGN_DATE") {
            setSelEmployee({
                ...selEmployee,
                resignDate: e
            });
        } else if (field === "IS_SUPERVISOR") {
            setSelEmployee({
                ...selEmployee,
                isSupervisor: e,
                noOnBehalf: !e ? false : selEmployee.noOnBehalf,
                noTeamManager: !e ? false : selEmployee.noTeamManager
            });
        } else if (field === "EMPLOYEE_GROUP") {
            setSelEmployee({
                ...selEmployee,
                employeeGroup: e
            });
        } else if (field === "RESET_TOKEN") {
            setSelEmployee({
                ...selEmployee,
                requestToken: e
            });
        } else if (field === "NO_ONBEHALF") {
            setSelEmployee({
                ...selEmployee,
                noOnBehalf: !selEmployee.isSupervisor ? false : e
            });
        } else if (field === "NO_TEAMMANAGER") {
            setSelEmployee({
                ...selEmployee,
                noTeamManager: !selEmployee.isSupervisor ? false : e
            });
        }
    };

    const onRequestReset = () => {
        if (selEmployee.id === "-1") {
            return;
        }

        dispatch(showMsg({
            title: "Resetting...",
            content: `Are you sure you want to reset password for employee ${ selEmployee.empNo } - ${ selEmployee.name }?`,
            id: "RESET_EMP_PWD",
            isPromptInput: true
        }));
    };

    const importCsv = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        const selFile = e.target.files[0];

        const rdr = new FileReader();

        rdr.onload = (f) => {
            let txt = f.target.result;

            txt = txt.replaceAll("\r", "");

            const txtLines = txt.split("\n");

            try {
                const emps = txtLines
                    .filter((row) => {
                        const empDat = row.split(",");

                        if (empDat.length < 10) {
                            return false;
                        }

                        if (empDat[0].toString().trim() == "" ||
                            empDat[1].toString().trim() == "" ||
                            empDat[6].toString().trim() == "") {
                            return false;
                        }

                        if (empDat[0].toString().trim() == "EmpNo" ||
                            empDat[1].toString().trim() == "Name" ||
                            empDat[6].toString().trim() == "HireDate") {
                            return false;
                        }

                        return true;
                    })
                    .map((row) => {
                        const empDat = row.split(",");

                        let dtHire = new Date('0001-01-01');

                        if (empDat[6] != "") {
                            dtHire = new Date(empDat[6]);
                        }

                        let dtResign = new Date('0001-01-01');

                        if (empDat[7] != "") {
                            dtResign = new Date(empDat[7]);
                        }

                        return {
                            empNo: empDat[0],
                            name: empDat[1],
                            firstName: empDat[2],
                            lastName: empDat[3],
                            email: empDat[4],
                            department: empDat[5],
                            hireDate: dtHire,
                            resignDate: dtResign,
                            isSupervisor: empDat[8].toString() === "Y" ? true : false,
                            employeeGroup: empDat[9]
                        };
                    });

                if (emps.length === 0) {
                    throw new Error("CSV does not contained any data or the data contained is invalid.");
                }

                setSelCsv(emps);
            } catch (ex) {
                dispatch(showMsg({
                    title: "Error",
                    content: `Unable to process selected file. ${ ex.message }`
                }));
            }
        };

        if (selFile.size / 100000 > 10) {
            dispatch(showMsg({
                title: "Error",
                content: "Unable to process selected CSV file. File exceeding 10MB size."
            }));

            return;
        }

        rdr.readAsText(selFile);
    };

    const uploadCsv = async (e) => {
        e.preventDefault();

        if (isCancel) {
            return;
        }

        dispatch(setLoadingNow());

        try {
            const {
                updatedRecords,
                ok,
                rej
            } = await UploadEmployeeMaster(selCsv);

            setEmpTokens(ok);

            await refreshList();

            setSelCsv(null);

            setSelFile(null);

            setShowImport(false);

            dispatch(showMsg({
                title: "Success",
                content: `CSV file has been successfully imported. 
                    Total success record: ${ updatedRecords }. Total rejected records: ${ rej.length }`,
                id: "SUCCESS_IMPORT",
                isPromptInput: false
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to upload CSV file. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const exportCsv = () => {
        const lst = ds.map((row) => row);
    
        let dwnCsv = "EmpNo,Name,FirstName,LastName,Email,Department,HireDate,ResignDate,IsSupervisor,EmployeeGroup,\n";
    
        lst.forEach((row) => {
          const {
            empNo, name,
            firstName, lastName,
            email, department,
            hireDate, resignDate,
            isSupervisor, employeeGroup
          } = row;
    
          dwnCsv += `${empNo},${name},${firstName},${lastName},${email},${department},${hireDate},${resignDate},${isSupervisor ? 'Y' : 'N'},${employeeGroup},\n`;
        });
    
        let a = document.createElement("a");
        a.style = "display: none";
    
        document.body.appendChild(a);
    
        const txtBlob = new Blob([dwnCsv], { type: "octet/stream" });
    
        const txtBlobUrl = URL.createObjectURL(txtBlob);
    
        a.href = txtBlobUrl;
        a.download = "employeemaster.csv";
        a.click();
    
        URL.revokeObjectURL(txtBlobUrl);
    
        document.body.removeChild(a);
    };

    const downloadToken = () => {
        if (empTokens.length === 0) {
            return;
        }

        let csvReqToken = "EmpNo,Name,RequestToken,\n";

        empTokens.forEach((row) => {
            const {
                empNo,
                name,
                requestToken
            } = row;

            csvReqToken += `${ empNo },${ name },${ requestToken },\n`;
        });

        let a = document.createElement("a");
        a.style = "display: none";

        document.body.appendChild(a);

        const txtBlob = new Blob([csvReqToken], { type: "octet/stream" });

        const txtBlobUrl = URL.createObjectURL(txtBlob);

        a.href = txtBlobUrl;
        a.download = "requestToken.csv";
        a.click();

        URL.revokeObjectURL(txtBlobUrl);

        document.body.removeChild(a);

        setEmpTokens([]);
    };

    const onResetRegisteredDevice = async (e) => {
        e.preventDefault();

        if (!showResetDevice) {
            return;
        }

        try {
            dispatch(setLoadingNow());

            await ResetRegisteredDevice({
                empNo: selEmployee.empNo,
                remark: remarkReset
            });

            setIsViewDevice(false);
                        
            setShowEditor(false);

            setSelEmployee(defaultEmployee);

            setRemarkReset("");

            setShowResetDevice(false);

            await refreshList();

            dispatch(showMsg({
                title: "Reset Success",
                content: "Device has been reset. The first device use to login will be registered."
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Device reset failed. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onShowDeviceResetLog = async () => {
        dispatch(setLoadingNow());

        try {
            const logs = await GetDeviceResetHistory(selEmployee.empNo);

            if (logs !== null) {
                const lst = logs.map((row) => {
                    const dt = new Date(row.resetDateTime);
    
                    return (
                        <div key={ `${ selEmployee.empNo }_dev_${ row.remark }` } className="listing-row">
                            <strong>{ dt.toLocaleString() }</strong>
                            <span>{ row.remark }</span>
                        </div>
                    );
                });
    
                setDeviceResetLog(lst);
            }

            setShowDeviceResetLog(true);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Fetching device reset history failed. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    useEffect(() => {
        (
            async () => {
                await refreshList();
            }
        )();
    }, []);

    useEffect(() => { 
        renderList();
        renderDevList(); 
    }, [ds]);

    useEffect(() => { renderList() }, [
        srchSel,
        srchEmpNo,
        srchName,
        srchDept,
        srchEmpGroup,
        srchHireDate
    ]);

    useEffect(() => { renderDevList() }, [srchDev]);

    useEffect(() => {
        if (pageSel.msgResult === false) {
            if (pageSel.msgId === "DEL_SINGLE_EMP") {
                setDelSingleEmp(null);
            }

            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());

                try {
                    if (pageSel.msgId === "DEL_SINGLE_EMP") {
                        await DeleteEmployeeMaster(delSingleEmp);

                        setDelSingleEmp(null);

                        await refreshList();

                        dispatch(hideMsg());
                    } else if (pageSel.msgId === "DEL_SEL_EMP") {
                        await onDeleteRows();
                        dispatch(hideMsg());
                    } else if (pageSel.msgId === "RESET_EMP_PWD") {
                        const token = await ResetRequestToken(selEmployee.empNo);

                        onFieldChange(token, "RESET_TOKEN");

                        await refreshList();

                        dispatch(showMsg({
                            title: "Reset Success",
                            content: "Password has been reset."
                        }));
                    } else if (pageSel.msgId === "SUCCESS_IMPORT") {
                        downloadToken();
                    } else if (pageSel.msgId === "RESET_DEV") {
                        await ResetRegisteredDevice(selEmployee.id);

                        setIsViewDevice(false);
                        
                        setShowEditor(false);

                        setSelEmployee(defaultEmployee);

                        await refreshList();

                        dispatch(showMsg({
                            title: "Reset Success",
                            content: "Device has been reset. The first device use to login will be registered."
                        }));
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ ex.message }`
                    }));
                } finally {
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    useEffect(() => {
        setIsResetPwd(false);
    
        setSelEmployee(defaultEmployee);

        setShowEditor(false);

        setShowImport(false);

        setIsCancel(false);    
    }, [isCancel]);

    useEffect(() => {
        if (!showDeviceResetLog) {
            setDeviceResetLog([]);
        }
    }, [showDeviceResetLog]);

    return (
        <>
            <strong className="title">Employee Master</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={ refreshList }/>
                    <Button label="New" src="/img/add.svg" onClick={ onAddNew }/>
                    <Button label="Delete" src="/img/remove.svg" onClick={ onDelete }/>
                </div>
                <div className="panel-inner panel-inner-end">
                    <Button label="Device Manager" src="/img/phone_android.svg" onClick={() => { setShowDevManager(true) }}/>
                    <Button label="Import" src="/img/arrow_downward.svg" onClick={() => { setShowImport(true) }}/>
                    <Button label="Export" src="/img/arrow_upward.svg" onClick={ exportCsv }/>
                </div>
            </div>
            <AutoTable th={ thEmp } onSearch={ onSearch }>
                { tblRows }
            </AutoTable>
            <div className={ showEditor ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "70vw" }}>
                    <div className="dialog-header">
                        <span>{ selEmployee.id === "-1" ? "New Employee" : `Edit ${ selEmployee.empNo } - ${ selEmployee.name }` }</span>
                        <button onClick={() => { setIsCancel(true) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ onSave }>
                        <span className="form-row">
                            <TextInput 
                                label="Employee No." 
                                required 
                                value={ selEmployee.empNo } 
                                onChange={(e) => { onFieldChange(e, "EMPNO") }}
                                readonly={ selEmployee.id !== "-1" }
                            />
                        </span>
                        <span className="form-row">
                            <TextInput label="Name" required value={ selEmployee.name } onChange={(e) => { onFieldChange(e, "NAME") }}/>
                        </span>
                        <div className="form-row">
                            <TextInput label="First Name" value={ selEmployee.firstName } onChange={(e) => { onFieldChange(e, "FIRST_NAME") }}/>
                            <TextInput label="Last Name" value={ selEmployee.lastName } onChange={(e) => { onFieldChange(e, "LAST_NAME") }}/>
                        </div>
                        <div className="form-row">
                            <TextInput label="Department" value={ selEmployee.department } onChange={(e) => { onFieldChange(e, "DEPARTMENT") }}/>
                            <TextInput label="Email" type="email" value={ selEmployee.email } onChange={(e) => { onFieldChange(e, "EMAIL") }}/>
                        </div>
                        <div className="form-row">
                            <ComboBox 
                                label="Employee Group" 
                                value={ selEmployee.employeeGroup } 
                                onChange={(e) => { onFieldChange(e.target.value, "EMPLOYEE_GROUP") }}
                                opts={ optEmpGroup }
                            />
                        </div>
                        <div className="form-row" style={{ alignItems: "flex-start" }}>
                            <TextInput 
                                type="date" 
                                label="Hire Date" 
                                required 
                                value={ FormatCompDate(new Date(selEmployee.hireDate)) } 
                                onChange={(e) => { onFieldChange(e, "HIRE_DATE") }}
                            />
                            {
                                selEmployee.id !== "-1" && (
                                    <TextInput 
                                        type="date" 
                                        label="Resign Date" 
                                        value={ FormatCompDate(new Date(selEmployee.resignDate)) } 
                                        onChange={(e) => { onFieldChange(e, "RESIGN_DATE") }}
                                    />
                                )
                            }
                            {
                                selEmployee.id === "-1" && (
                                    <div></div>
                                )
                            }
                        </div>
                        <div className="form-row">
                            <CheckBox
                                label="Supervisor"
                                checked={selEmployee.isSupervisor}
                                onChange={() => { onFieldChange(!selEmployee.isSupervisor, "IS_SUPERVISOR") }}
                            />
                        </div>
                        <div className="form-row" style={{ opacity: selEmployee.isSupervisor ? 1 : 0.3 }}>
                            <CheckBox 
                                label="Disable On-behalf Clocking" 
                                checked={selEmployee.noOnBehalf} 
                                onChange={() => { onFieldChange(!selEmployee.noOnBehalf, "NO_ONBEHALF") }}
                            />
                            <CheckBox 
                                label="Disable Team Manager" 
                                checked={selEmployee.noTeamManager} 
                                onChange={() => { onFieldChange(!selEmployee.noTeamManager, "NO_TEAMMANAGER") }}
                            />
                        </div>
                        {
                            selEmployee.requestToken !== "" && selEmployee.requestToken !== null && (
                                <div className="form-row">
                                    <TextInput label="Request Token" readonly={ true } value={ selEmployee.requestToken }/>
                                    <div></div>
                                </div>
                            )
                        }
                        <div className="form-row form-row-border-top form-bottom">
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button label="Reset Password" src="/img/lock_reset.svg" onClick={() => { setIsResetPwd(true); onRequestReset(); }}/>
                                <Button label="Registered Device" src="/img/phone_android.svg" onClick={() => { setIsViewDevice(true) }} />        
                            </div>
                            <div className="form-row" style={{ padding: 0 }}>
                                <SaveButton /> 
                                <CancelButton onClick={() => { setIsCancel(true) }}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className={ showImport ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "30vw" }}>
                    <div className="dialog-header">
                        <span>Import from CSV</span>
                        <button onClick={() => { setIsCancel(true) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ uploadCsv }>
                        <div className="form-row">
                            <input required value={ selFile } style={{ paddingTop: "7px", paddingBottom: "7px" }} type="file" accept=".csv" onChange={ importCsv } />
                        </div>
                        <div className="form-row form-row-border-top">
                            <div></div>
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button type="submit" label="Import" src="/img/arrow_downward.svg" />
                                <CancelButton onClick={() => { setIsCancel(true) }} />
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
            <div className={ isViewDevice ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "40vw" }}>
                    <div className="dialog-header">
                        <span>Registered Device</span>
                        <button onClick={() => { setIsViewDevice(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <div className="dialog-body">
                        {
                            (selEmployee.device !== null && selEmployee.device !== undefined) && (
                                <>
                                    <div className="form-row">
                                        <TextInput label="Device GUID" value={ selEmployee.device.deviceGUID } readOnly />
                                        <TextInput label="Device Name" value={ selEmployee.device.deviceName } readOnly />
                                    </div>
                                    <div className="form-row">
                                        <TextInput label="Manufacturer" value={ selEmployee.device.manufacturer } readOnly />
                                        <TextInput label="Model Name" value={ selEmployee.device.modelName } readOnly />
                                    </div>
                                </>
                            )
                        }
                        {
                            (selEmployee.device === null || selEmployee.device === undefined) && selEmployee.deviceHash !== "" && (
                                <div className="form-row">
                                    <TextInput label="Device Hash" value={ selEmployee.deviceHash } readOnly />
                                    <TextInput label="Device Token" value={ selEmployee.deviceHash === null || selEmployee.deviceHash === "" ? "Un-registered" : "Legacy" } readOnly />
                                </div>
                            )
                        }
                        <div className="form-row form-row-border-top">
                            <div></div>
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button label="Reset Registration" src="/img/refresh.svg" onClick={() => { setShowResetDevice(true) }} />
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <div className={ showDevManager ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "70vw" }}>
                    <div className="dialog-header">
                        <span>Device Manager</span>
                        <button onClick={() => { setShowDevManager(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <div className="dialog-body device-dialog-body">
                        <div className="device-list">
                            <input className="listing-search-box" type="text" placeholder="search" value={ srchDev } onChange={(e) => { setSrchDev(e.target.value) }}/>
                            { empDevs }
                        </div>
                        <div className="device-details">
                            <div className="form-section">
                                <strong>Device Details</strong>
                            </div>
                            <div className="form-row">
                                <TextInput label="Employee No." value={ selEmployee.empNo } readonly />
                            </div>
                            <div className="form-row">
                                <TextInput label="Name" value={ selEmployee.name } readonly />
                            </div>
                            {
                                (selEmployee.device !== undefined && selEmployee.device !== null) && (
                                    <>
                                    <div className="form-row">
                                        <TextInput label="Device GUID" value={ selEmployee.device.deviceGUID } readonly />
                                        <TextInput label="Device Name" value={ selEmployee.device.deviceName } readonly />
                                    </div>
                                    <div className="form-row">
                                        <TextInput label="Manufacturer" value={ selEmployee.device.manufacturer } readonly />
                                        <TextInput label="Model Name" value={ selEmployee.device.modelName } readonly />
                                    </div>
                                    </>
                                )
                            }
                            {
                                (selEmployee.device === null || selEmployee.device === undefined) && (
                                    <>
                                    <div className="form-row">
                                        <TextInput label="App. Version" value="Legacy" readonly />
                                    </div>
                                    </>
                                )
                            }
                            {
                                selEmployee.id !== "-1" && (
                                    <div className="form-row">
                                        <Button label="Reset History" src="/img/history.svg" onClick={ onShowDeviceResetLog } />
                                        <Button label="Reset Registration" src="/img/refresh.svg" onClick={() => { setShowResetDevice(true) }} />                          
                                    </div>
                                )
                            }                            
                        </div>
                    </div>
                </div>
            </div>
            <div className={ showResetDevice ? "dialog-bg dialog-bg-show" : "dialog-bg" } style={{ zIndex: 4 }}>
                <div className="dialog" style={{ width: "30vw" }}>
                    <div className="dialog-header">
                        <span>Resetting Device...</span>
                        <button onClick={() => { setShowResetDevice(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ onResetRegisteredDevice }>
                        <div className="form-row">
                            <TextInput label="Remark" value={ remarkReset } onChange={(e) => { setRemarkReset(e) }}/>
                        </div>
                        <div className="form-row form-row-border-top">
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button type="submit" label="Reset Now" src="/img/refresh.svg" />
                                <CancelButton onClick={() => { setShowResetDevice(false) }} />
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
            <div className={ showDeviceResetLog ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "40vw" }}>
                    <div className="dialog-header">
                        <span>Device Reset History</span>
                        <button onClick={() => { setShowDeviceResetLog(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <div className="dialog-body">
                        { deviceResetLog }
                        { deviceResetLog.length === 0 && (
                            <div className="listing-row">No history found</div>
                        ) }
                    </div>
                </div>
            </div>

            <Help sectionName="Employee Master">
                <h3>Register New Employee</h3>
                <ul>
                    <li>Click on <strong>"New"</strong> button.</li>
                    <li>Enter particulars.</li>
                    <li>If the employee does not have email address or an access to email address, leave it blank.</li>
                    <li>Click <strong>"Save"</strong> button to save changes.</li>
                    <li>If email address is entered, request token will be sent via email.</li> 
                    <li>If email address is <strong>not</strong> presented, request token can be obtain from employee's info.</li>
                </ul>
                <h3>Filter Employee Master</h3>
                <ul>
                    <li>To filter employee master, enter a search key in filter text box for respective column as shown in below sample diagram.</li>
                </ul>
                <img className="diagram" src="/img/manual/filter_employee_master.png" />
                <h3>Reset Employee Password</h3>
                <ul>
                    <li>To reset employee password, go to employee info.</li>
                    <li>Click on <strong>"Reset Password"</strong> button.</li>
                    <li>If email address is present in employee particulars, request token will be sent via email.</li>
                    <li>If email address is <strong>not</strong> present in employee particulars, request token can be obtained from employee info.</li>
                </ul>
                <h3>Export Employee Master</h3>
                <ul>
                    <li>Click on <strong>"Export"</strong> button located on top-right side.</li>
                    <li>Employee master will be exported in CSV file format.</li>
                    <li>iFlexi Clock will export all employee data, regardless of filter applied.</li>
                </ul>
                <h3>Import Employee Master</h3>
                <ul>
                    <li>To begin, template can be obtain from <strong>export process</strong>.</li>
                    <li>After export is complete, insert employee particulars.</li>
                    <li>If employee already exists in Employee Master and particulars in CSV found newer, existing data will be updated with newer particular found in the CSV file.</li>
                    <li>To start importing updated CSV file, click on <strong>"Import"</strong> button and select the CSV file.</li>
                    <li>CSV file size must not exceeding 10MB in size.</li>
                    <li>Click <strong>"Import"</strong> button to proceed.</li>
                </ul>
                <h3>Supervisor</h3>
                <p>With supervisor option enabled, a supervisor capable to view defined subordinate clock data.
                    By default, a supervisor has access to perform clocking on-behalf of subordinate and managing their team by themself.
                    To limit supervisor access to these features,</p>
                    <ul>
                        <li>Click on <strong>"Disable On-behalf Clocking"</strong> to disallow supervisor from perform clocking on-behalf of subordinate.</li>
                        <li>Click on <strong>"Disable Team Manager"</strong> to disallow supervisor from managing subordinate by themselves.</li>
                    </ul>
                <h3>Device Manager</h3>
                <p>Device manager is used to manage employees device. This to ensure login can only be made on one device for each employee.</p>
                <p>In case of employee has new device, device reset is required. 
                    There are two methods to reset registered device; employee can request password reset or un-register the registered device.</p>
                <ul>
                    <li>To un-register employee device, select on employee record.</li>
                    <li>Click on <strong>"Reset Registration"</strong>.</li>
                    <li>The first login after the reset, the device used will be registered.</li>
                </ul>
                <h3>Troubleshoot</h3>
                <ul>
                    <li>
                        <strong>User unable to login or verify account on new device. Mobile app. prompt "device has been registered by another user".</strong>
                        <ul>
                            <li>Make sure iFlexi Clock mobile app. is updated to latest version.</li>
                            <li>Try to change device name.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Request token expired.</strong>
                        <ul>
                            <li>Try to request password reset again from mobile app. or web app.</li>
                        </ul>
                    </li>
                </ul>
            </Help>
        </>
    );
}

const thEmp = [
    { id: 0, name: " ", span: 1, width: "5%", type: "checkbox" },
    { id: 1, name: "Employee No.", span: 1, width: "15%", type: "text" },
    { id: 2, name: "Name", span: 1, width: "25%", type: "text" },
    { id: 3, name: "Department", span: 1, width: "15%", type: "text" },
    { id: 4, name: "Employee Group", span: 1, width: "20%", type: "text" },
    { id: 5, name: "Hire Date", span: 1, width: "10%", type: "text" },
    { id: 6, name: "Action", span: 2, width: "10%" }
];

function EmployeeRow(props) {
    const [empNo, setEmpNo] = useState();
    const [name, setName] = useState();
    const [dept, setDept] = useState();
    const [empGroup, setEmpGroup] = useState();
    const [hireDt, setHireDt] = useState();

    const onEdit = () => { props.onEdit(props.data) };

    const onDelete = () => { props.onDelete(props.data.id) };

    const onSelectChange = (e) => {
        props.onSelectChange(props.data.id);
    };

    useEffect(() => {
        const {
            empNo,
            name,
            department,
            employeeGroup,
            hireDate
        } = props.data;

        setEmpNo(empNo);
        setName(name);
        setDept(department);
        setEmpGroup(employeeGroup);

        const dateHire = new Date(hireDate);

        setHireDt(dateHire.toLocaleDateString());
    }, [props.data]);

    return (
        <tr className="autotable-tr" scope="row">
            <td className="autotable-td" style={{ width: "5%", textAlign: "center" }}><input type="checkbox" checked={ props.selected } onChange={ onSelectChange } /></td>
            <td className="autotable-td" style={{ width: "15%" }}>{ empNo }</td>
            <td className="autotable-td" style={{ width: "25%" }}>{ name }</td>
            <td className="autotable-td" style={{ width: "15%" }}>{ dept }</td>
            <td className="autotable-td" style={{ width: "20%" }}>{ empGroup }</td>
            <td className="autotable-td" style={{ width: "10%" }}>{ hireDt }</td>
            <td className="autotable-td" style={{ width: "5%", textAlign: "center" }}><ActionButton src="/img/edit_white.svg" onClick={ onEdit }/></td>
            <td className="autotable-td" style={{ width: "5%", textAlign: "center" }}><ActionButton src="/img/delete_white.svg" onClick={ onDelete }/></td>
        </tr>
    );
}

const defaultEmployee = {
    sel: false,
    id: "-1",
    empNo: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    department: "",
    hireDate: new Date(),
    resignDate: new Date("1970-01-01T00:00:00.000Z"),
    deviceHash: "",
    requestToken: "",
    isSupervisor: false,
    employeeGroup: "",
    device: null,
    noOnBehalf: false,
    noTeamManager: false
};
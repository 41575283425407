import {
    useState,
    useEffect
} from "react";

import { useDispatch } from "react-redux";

import { 
    showMsg, 
    setLoadingNow,
    setLoadingComplete
} from "../store/pageSlice";

import AutoTable from "../components/AutoTable";

import {
    Button,
    TextInput,
    TextArea,
    CancelButton,
    CheckBoxCollection,
    ComboBox
} from "../components/Input";

import * as clk from "../api/clockData";

import { GetExportConf } from "../api/exportConfig";
import { GetClockDataTag } from "../api/clockDataTag";
import { GetEmployeeGroup } from "../api/employeeGroup";
import { GetEmployeeMaster } from "../api/employeeMaster";
import { ExportClockData } from "../api/exportClockData";

import Help from "./Help";

import "./ClockData.css";
import "../App.css";
import "azure-maps-control/dist/atlas.min.css";

const Atlas = require("azure-maps-control");

export default function ClockData() {
    const dispatch = useDispatch();

    const [lstClock, setLstClock] = useState();

    const [dtStart, setDtStart] = useState(new Date());
    const [dtEnd, setDtEnd] = useState(new Date());

    const [dsClk, setDsClk] = useState([]);

    const [selClk, setSelClk] = useState(defaultClockData);

    const [map, setMap] = useState();
    const [mapLocation, setMapLocation] = useState([ 103.78610070962547, 1.549652396775663 ]);
    const [mapPoint, setMapPoint] = useState();

    const [exportConfOpt, setExportConfOpt] = useState([]);
    const [exportConfSrc, setExportConfSrc] = useState([]);
    const [clockDataTagOpts, setClockDataTagOpts] = useState([]);
    const [employeeGroupOpts, setEmployeeGroupOpts] = useState([]);
    const [employeeMasterOpts, setEmployeeMasterOpts] = useState([]);

    const [selExpConfId, setSelExpConfId] = useState("");
    const [selExpClockDataTag, setSelExpClockDataTag] = useState("");
    const [selExpEmployeeGroup, setSelExpEmployeeGroup] = useState("");
    const [selExpEmployeeMaster, setSelExpEmployeeMaster] = useState("");

    const [showFilter, setShowFilter] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    const [srchClockTime, setSrchClockTime] = useState("");
    const [srchClockDataTag, setSrchClockDataTag] = useState("");
    const [srchEmpNo, setSrchEmpNo] = useState("");
    const [srchName, setSrchName] = useState("");
    const [srchEmployeeGroup, setSrchEmployeeGroup] = useState("");
    const [srchLocation, setSrchLocation] = useState("");

    const [refreshListNow, setRefreshListNow] = useState(false);

    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const res = await clk.GetClockData(dtStart, dtEnd);
            setDsClk(res);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve clock data for selected date. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
            setRefreshListNow(false);
        }
    };

    const renderList = () => {
        let dsTemp = dsClk.map((row) => row);

        let lst = [];

        dispatch(setLoadingNow());

        for (let i = 0; i < 6; i++) {
            if (i === 0 && srchClockTime !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const isClkTime = new Date(row.clockTime).toLocaleString().toLowerCase();
                    return isClkTime.indexOf(srchClockTime.toLowerCase()) > -1;
                });
            } else if (i === 1 && srchClockDataTag !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const isClockDataTag = row.clockDataTag.toLowerCase();
                    return isClockDataTag.indexOf(srchClockDataTag.toLowerCase()) > -1;
                });
            } else if (i === 2 && srchEmpNo !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const isEmpNo = row.empNo.toLowerCase();
                    return isEmpNo.indexOf(srchEmpNo.toLowerCase()) > -1;
                });
            } else if (i === 3 && srchName !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const isName = row.name.toLowerCase();
                    return isName.indexOf(srchName.toLowerCase()) > -1;
                });
            } else if (i === 4 && srchEmployeeGroup !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const isEmployeeGroup = row.employeeGroup.toLowerCase();
                    return isEmployeeGroup.indexOf(srchEmployeeGroup.toLowerCase()) > -1;
                });
            } else if (i === 5 && srchLocation !== "") {
                dsTemp = dsTemp.filter((row) => {
                    const isLocation = row.location.toLowerCase();
                    return isLocation.indexOf(srchLocation.toLowerCase()) > -1;
                });
            }
        }

        lst = dsTemp.map((row) => (
            <ClockDataRow 
                key={ row.id } 
                data={ row } 
                onClick={ onShowDetail } 
            />
        ));

        setLstClock(lst);

        dispatch(setLoadingComplete());
    };

    const filterList = async (e) => {
        e.preventDefault();

        if (!showFilter) {
            return;
        }

        setRefreshListNow(true);

        setShowFilter(false);
    };

    const onShowDetail = (data) => {
        setSelClk(data);

        setShowDetail(true);

        setMapLocation([ Number(data.longitude), Number(data.latitude) ]);
    };

    const onSearch = (i, e) => {
        switch (i) {
            case 0:
                setSrchClockTime(e);
                break;
            case 1:
                setSrchClockDataTag(e);
                break;
            case 2:
                setSrchEmpNo(e);
                break;
            case 3:
                setSrchName(e);
                break;
            case 4:
                setSrchEmployeeGroup(e);
                break;
            case 5:
                setSrchLocation(e);
                break;
        }
    };

    const onRefresh = async () => {
        const dtSt = new Date();

        dtSt.setHours(0, 0, 0);

        setDtStart(dtSt);

        const dtEd = new Date();

        dtEd.setHours(23, 59, 59, 999);

        setDtEnd(dtEd);

        setRefreshListNow(true);
    };

    const getFilename = (id) => {
        const res = exportConfSrc.filter((row) => row.id === id);

        const r = /[A-Za-z0-9_-]+\.[A-Za-z0-9]+$/;

        if (r.exec(res[0]["name"].toString()) !== null) {
            return res[0]["name"].toString();
        }

        return `${res[0]["name"].toString()}.txt`;
    };

    const onExport = async (e) => {
        e.preventDefault();

        if (!showExport) {
            return;
        }

        try {
            if (selExpConfId === "") {
                throw new Error("Please select export template.");
            }

            const params = {
                id: selExpConfId,
                dateStart: dtStart,
                dateEnd: dtEnd,
                timezoneOffset: new Date().getTimezoneOffset(),
                selectEmp: selExpEmployeeMaster,
                selectEmpGroup: selExpEmployeeGroup,
                selectClockTag: selExpClockDataTag
            };

            const res = await ExportClockData(params);

            let a = document.createElement("a");
            a.style = "display: none";

            document.body.appendChild(a);

            const txt = res;

            const txtBlob = new Blob([txt], { type: "octet/stream" });

            const txtBlobUrl = URL.createObjectURL(txtBlob);

            a.href = txtBlobUrl;
            a.download = getFilename(selExpConfId);
            a.click();

            URL.revokeObjectURL(txtBlobUrl);

            document.body.removeChild(a);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to export clock data. ${ ex.message }`
            }));
        }
    };

    const onCheckClockDataTag = (e) => {
        let res = "";

        e.forEach((row) => {
            res += `${ row },`;
        });

        setSelExpClockDataTag(res);
    };

    const onCheckEmployeeGroup = (e) => {
        let res = "";

        e.forEach((row) => {
            res += `${ row },`;
        });

        setSelExpEmployeeGroup(res);
    };
    
    const onCheckEmployeeMaster = (e) => {
        let res = "";

        e.forEach((row) => {
            res += `${ row },`;
        });

        setSelExpEmployeeMaster(res);
    };

    useEffect(() => {
        try {
            const mapObj = new Atlas.Map("map", {
                view: "Auto",
                center: mapLocation,
                zoom: 15,
                language: "en-US",
                authOptions: {
                    authType: "subscriptionKey",
                    subscriptionKey: "C_Vmoi1XoLlF2st8DECCgQ16w7vPJrjpy4-N3TY5P7M"
                }
            });

            mapObj.events.add("ready", () => {
                mapObj.controls.add(new Atlas.control.ZoomControl(), { position: "bottom-right" });

                mapObj.controls.add(new Atlas.control.StyleControl({
                    mapStyles: ['road', 'grayscale_dark', 'night', 'road_shaded_relief', 'satellite', 'satellite_road_labels'],
                    layout: 'list'
                  }), {
                    position: 'top-right'
                  });  

                const ds = new Atlas.source.DataSource();

                mapObj.sources.add(ds);

                const layer = new Atlas.layer.SymbolLayer(ds, null);

                mapObj.layers.add(layer);

                const tPoint = new Atlas.Shape(new Atlas.data.Point(mapLocation));

                ds.add(tPoint);

                setMapPoint(tPoint);

                setMap(mapObj);
            });

            setMap(mapObj);
        } catch (ex) {
            console.error("Error init map", ex);
        }

        (
            async () => {
                await onRefresh();
            }
        )();
    }, []);

    useEffect(() => { renderList() }, [dsClk]);

    useEffect(() => {
        if (map === null || map === undefined || 
            mapPoint === null || mapPoint === undefined) {
            return;
        }

        map.setCamera({
            center: mapLocation,
            zoom: 15,
            duration: 1000,
            type: "fly"
        });

        mapPoint.setCoordinates(mapLocation);
    }, [mapLocation]);

    useEffect(() => {
        renderList();
    }, [
        srchClockTime,
        srchClockDataTag,
        srchEmpNo,
        srchName,
        srchEmployeeGroup,
        srchLocation
    ]);

    useEffect(() => {
        if (!showExport) {
            return;
        }

        (
            async () => {
                try {
                    const res = await GetExportConf();

                    setExportConfSrc(res);
        
                    const opts = res.map((row) => (
                        <option key={ row.id } value={ row.id }>{ row.name }</option>
                    ));
        
                    setExportConfOpt(opts);

                    if (res.length > 0) {
                        setSelExpConfId(res[0]["id"]);
                    }
        
                    const resClockDataTag = await GetClockDataTag();
        
                    const clkDatTagOpts = resClockDataTag.map((row) => (
                        {
                            id: row.tag,
                            label: row.label
                        }
                    ));
        
                    setClockDataTagOpts(clkDatTagOpts);
        
                    const resEmployeeGroup = await GetEmployeeGroup();
        
                    const empGroupOpts = resEmployeeGroup.map((row) => ({
                        id: row.name,
                        label: row.name
                    }));
        
                    setEmployeeGroupOpts(empGroupOpts);

                    const resEmployeeMaster = await GetEmployeeMaster();

                    const empMasterOpts = resEmployeeMaster.map((row) => ({
                        id: row.empNo,
                        label: `${ row.empNo } - ${ row.name }`
                    }));

                    setEmployeeMasterOpts(empMasterOpts);
                } catch (ex) {
                    console.error("Export Enum Init", ex);
                }
            }
        )();
    }, [showExport]);

    useEffect(() => {
        if (!refreshListNow) {
            return;
        }

        (
            async () => { await refreshList() }
        )();
    }, [refreshListNow]);

    return (
        <>
            <strong className="title">Home - Clock Data</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={ onRefresh }/>
                    <Button label="Filter" src="/img/filter.svg" onClick={() => { setShowFilter(true) }}/>
                </div>
                <div className="panel-inner panel-inner-end">
                    <Button label="Export" src="/img/arrow_upward.svg" onClick={() => { setShowExport(true) }}/>
                </div>                
            </div>
            <AutoTable 
                className="autotable-container clock-data-table" 
                th={ thClockData }
                onSearch={ onSearch }
            >
                { lstClock }
            </AutoTable>
            <div className={ showDetail ? "clock-data-detail-panel clock-data-detail-panel-show" : "clock-data-detail-panel" }>
                <div className="form-title" style={{ top: 0, position: "sticky", zIndex: 2 }}>
                    <div className="detail-panel-title">
                        <strong>Details</strong>
                        <button onClick={() => { setShowDetail(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                </div>
                <div className="map" id="map"></div>
                <div className="form-row">
                    <TextInput label="Tag" readonly value={ selClk.clockDataTag }/>
                </div>
                <div className="form-row">
                    <TextInput label="Clock Time" readonly value={ new Date(selClk.clockTime).toLocaleString() }/>
                </div>
                <div className="form-row">
                    <TextInput label="Employee Number" readonly value={ selClk.empNo }/>
                </div>
                <div className="form-row">
                    <TextInput label="Name" readonly value={ selClk.name }/>
                </div>
                <div className="form-row">
                    <TextArea label="Location" readonly value={ selClk.location }/>
                </div>
                <div className="form-row">
                    <TextInput label="Coordinate" readonly value={ `${ selClk.latitude },${ selClk.longitude }` }/>
                </div>
                <div className="form-row">
                    <TextArea label="Remark" readonly value={ selClk.remark }/>
                </div>
                <div className="form-row">
                    <TextInput label="Employee Group" readonly value={ selClk.employeeGroup }/>
                </div>
                <div className="form-row">
                    <TextInput label="Supervisor" readonly value={ selClk.supervisor }/>
                </div>
            </div>
            <div className={ showFilter ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "700px" }}>
                    <div className="dialog-header">
                        <span>Filter</span>
                        <button onClick={() => { setShowFilter(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ filterList }>
                        <div className="form-row">
                            <strong>Date</strong>
                        </div>
                        <div className="form-row">
                            <TextInput label="Start" type="datetime-local" required value={ dtStart } onChange={(e) => { setDtStart(e) }}/>
                            <TextInput label="End" type="datetime-local" required value={ dtEnd } onChange={(e) => { setDtEnd(e) }}/>
                        </div>
                        <div className="form-row form-row-border-top">
                            <div></div>
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button type="submit" label="Apply" src="/img/check.svg" />
                                <CancelButton onClick={() => { setShowFilter(false) }} />
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
            <div className={ showExport ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
                <div className="dialog" style={{ width: "700px" }}>
                    <div className="dialog-header">
                        <span>Export</span>
                        <button onClick={() => { setShowExport(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ onExport }>
                        <div className="form-row">
                            <ComboBox label="Export Template" onChange={(e) => { setSelExpConfId(e.target.value) }} horizontal required opts={ exportConfOpt }/>
                        </div>
                        <div className="form-row">
                            <TextInput label="Date Start" required readonly value={ new Date(dtStart).toLocaleString() }/>
                            <TextInput label="Date End" required readonly value={ new Date(dtEnd).toLocaleString() }/>
                        </div>
                        <div className="form-row">
                            <div>
                                <span>Clock Data Tag</span>
                                <CheckBoxCollection style={{ height: "30vh", marginTop: "10px" }} data={ clockDataTagOpts } onSelectionChange={ onCheckClockDataTag } search/>
                            </div>
                            <div>
                                <span>Employee Group</span>
                                <CheckBoxCollection style={{ height: "30vh", marginTop: "10px" }} data={ employeeGroupOpts } onSelectionChange={ onCheckEmployeeGroup } search/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div>
                                <span>Employee</span>
                                <CheckBoxCollection style={{ height: "30vh", marginTop: "10px" }} data={ employeeMasterOpts } onSelectionChange={ onCheckEmployeeMaster } search/>
                            </div>
                        </div>
                        <div className="form-row form-row-border-top form-bottom">
                            <div></div>
                            <div className="form-row" style={{ padding: 0 }}>
                                <Button type="submit" label="Export" src="/img/arrow_downward.svg" />
                                <CancelButton onClick={() => { setShowExport(false) }}/>
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
            <Help sectionName="Clock Data">
                <h3>Load Clock Data</h3>
                <ul>
                    <li>First, select clock data clock time range. To start click on <strong>"Filter"</strong> button.</li>
                    <li>Select date and time start; and select date and time end.</li>
                    <li>Click on <strong>"Apply"</strong> button.</li>
                </ul>
                <h3>To View Clock Data Details</h3>
                <ul>
                    <li>Click on clock data row. Right side-panel will appear.</li>
                </ul>
                <h3>Filter Current Loaded Clock Data</h3>
                <ul>
                    <li>Enter search key in filter text box for respective column as shown in sample diagram below.</li>
                    <li>The sample diagram below shown filtering by <strong>employee number</strong></li>
                </ul>
                <img className="diagram" src="/img/manual/filter_clock_data.png" />
                <h3>Export Clock Data</h3>
                <ul>
                    <li>To export clock data, click on <strong>"Export"</strong> button.</li>
                    <li>Select export template.</li>
                    <li>Optionally, select clock data tag, employee group and employee to filter exported data. If none of the
                        filter criteria selected, all clock data based on selected clock and time range will be exported.
                    </li>
                    <li>Click on <strong>"Export"</strong> button to begin export process.</li>
                </ul>
                <h3>Create Export Template</h3>
                <ul>
                    <li>Export template can be created and managed from <strong>"Export Template"</strong> page.</li>
                </ul>
            </Help>
        </>
    );
}

const thClockData = [
    { id: 0, name: "Clock Time", span: 1, width: "15%", type: "text" },
    { id: 1, name: "Tag", span: 1, width: "10%", type: "text" },
    { id: 2, name: "Emp. Number", span: 1, width: "12%", type: "text" },
    { id: 3, name: "Name", span: 1, width: "19%", type: "text" },
    { id: 4, name: "Emp. Group", span: 1, width: "14%", type: "text" },
    { id: 5, name: "Location", span: 1, width: "30%", type: "text" }
];

const defaultClockData = {
    id: "-1",
    empNo: "",
    name: "",
    employeeGroup: "",
    clockTime: "1970-01-01T00:00:00.000Z",
    location: "",
    clockDataTag: "",
    qrCode: "",
    supervisor: "",
    remark: ""
};

function ClockDataRow(props) {
    const [clkTime, setClkTime] = useState();

    useEffect(() => {
        const dt = new Date(props.data.clockTime);
        setClkTime(dt.toLocaleString());
    }, []);

    const onClick = () => {
        props.onClick(props.data);
    };

    return (
        <tr className="autotable-tr" scope="row" onClick={ onClick }>
            <td className="autotable-td" style={{ width: "15%", textAlign: "center" }}>{ clkTime }</td>
            <td className="autotable-td" style={{ width: "10%", textAlign: "center" }}>{ props.data.clockDataTag }</td>
            <td className="autotable-td" style={{ width: "12%", textAlign: "center" }}>{ props.data.empNo }</td>
            <td className="autotable-td" style={{ width: "19%" }}>{ props.data.name }</td>
            <td className="autotable-td" style={{ width: "14%", textAlign: "center" }}>{ props.data.employeeGroup }</td>
            <td className="autotable-td" style={{ width: "30%" }}>{ 
                props.data.location !== "" && props.data.location !== null ? props.data.location : `${ props.data.latitude },${ props.data.longitude }`
            }</td>
        </tr>
    );
}
import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import pageSlice from "./pageSlice";

export default configureStore({
    reducer: {
        auth: authSlice,
        page: pageSlice
    }
});
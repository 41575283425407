export function FormatDecoDate(dt) {
  return (GetDayStr(dt.getDay()) + ", " + dt.getDate().toString() + " " + GetMonthStr(dt.getMonth()) + " " + dt.getFullYear().toString());
}

export function FormatDate(dt,sep) {
  let mth = dt.getMonth() + 1;

  return dt.getDate().toString() + sep + mth + sep + dt.getFullYear().toString();
}

export function FormatCompDate(dt) {
  const mth = PadValue(dt.getMonth() + 1, 10, 2);
  const dy = PadValue(dt.getDate(), 10, 2);

  return `${dt.getFullYear()}-${mth}-${dy}`;
}

export function FormatDateParam(dt) {
  let mth = dt.getMonth() + 1;

  return dt.getFullYear().toString() + "-" + mth + "-" + dt.getDate().toString();
}

export function FormatTime(dt) {
  let h = dt.getHours() <= 12 ? dt.getHours() : dt.getHours() - 12;

  if (h == 0) {
    h = 12;
  }

  return (
    PadValue(h, 10, 2) + ":" + PadValue(dt.getMinutes(), 10, 2) +
    ":" + PadValue(dt.getSeconds(), 10, 2) + " " +
    (dt.getHours() < 12 ? "AM" : "PM")
  );
}

export function FormatDateTime(dt, sep) {
  return FormatDate(dt, sep) + " " + FormatTime(dt);
}

export function FormatCompDateTime(dt) {
  let timeStr = `${PadValue(dt.getHours(), 10, 2)}:${PadValue(dt.getMinutes(), 10, 2)}:${PadValue(dt.getSeconds(), 10, 2)}`;

  return(
    `${FormatCompDate(dt)} ${timeStr}`
  );
}

export function FormatTimeHHMM(dt) {
  return PadValue(dt.getHours(), 10, 2) + PadValue(dt.getMinutes(), 10, 2);
}

export function PadValue(val, valCeil, valLen) {
  if (val < valCeil) {
    let sz = valLen - val.toString().length;
    let result = val.toString();

    for (var i = 0; i < sz; i++)
      result = "0" + result;

    return result;
  } else {
    return val;
  }
}

export function GetMonthStr(val) {
  let month = "";

  switch (val) {
    case 0:
      month = "January";
      break;
    case 1:
      month = "February";
      break;
    case 2:
      month = "March";
      break;
    case 3:
      month = "April";
      break;
    case 4:
      month = "May";
      break;
    case 5:
      month = "June";
      break;
    case 6:
      month = "July";
      break;
    case 7:
      month = "August";
      break;
    case 8:
      month = "September";
      break;
    case 9:
      month = "October";
      break;
    case 10:
      month = "November";
      break;
    case 11:
      month = "December";
      break;
  }

  return month;
}

export function GetDayStr(val) {
  let day = "";

  switch (val) {
    case 0:
      day = "Sunday";
      break;
    case 1:
      day = "Monday";
      break;
    case 2:
      day = "Tuesday";
      break;
    case 3:
      day = "Wednesday";
      break;
    case 4:
      day = "Thursday";
      break;
    case 5:
      day = "Friday";
      break;
    case 6:
      day = "Saturday";
      break;
  }

  return day;
}
